<template>
  <div class="auth-right">
    <div class="auth-right-header zoom">
      <router-link to="/auth/login" class="btn btn-outline-primary">
         {{$t("buttons.backtologin")}}
      </router-link>
      <!--<a href="#" class="menu-btn">
        <v-icon icon="bars" />
      </a> -->
    </div>
    <loading :loading="loading" />

     <div style="margin-top:180px">

    </div>
    <div v-show="!loading" class="auth-right-content">
      <!-- <h3 class="auth-titles-big text-secondary text-left">Reset Password</h3> -->
      <h3 class="auth-title-semi mt-4">{{$t("auth.password.enter-pass-text")}}</h3>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(formSubmit)" action="#">
          <div class="form-group mb-5">
            <validation-provider
              name="password"
              :rules="{ required: true, min: 8 }"
              v-slot="validationContext"
            >
              <label for="#signInPw">
                {{ $t("auth.general.passwordLabel") }}
              </label>
              <div
                class="input-group input-icon bg"
                :class="{
                  'is-invalid':
                    !getValidationState(validationContext) &&
                    validationContext.errors[0],
                }"
              >
                <b-form-input
                  :placeholder="$t('auth.general.password')"
                  type="password"
                  id
                  name="password"
                  v-model.trim="form.pwNew"
                ></b-form-input>
                <div class="input-group-append">
                  <span class="input-group-text">
                    <v-icon :icon="['fas', 'lock']" />
                  </span>
                </div>
              </div>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </validation-provider>
          </div>
          <div class="form-group mb-5">
            <validation-provider
              name="password"
              :rules="{ required: true, min: 8 }"
              v-slot="validationContext"
            >
              <label for="#signInPw">
                {{ $t("auth.general.repeat-password") }}
              </label>
              <div
                class="input-group input-icon bg"
                :class="{
                  'is-invalid':
                    !getValidationState(validationContext) &&
                    validationContext.errors[0],
                }"
              >
                <b-form-input
                  :placeholder="$t('auth.general.password')"
                  type="password"
                  id
                  name="password"
                  v-model.trim="form.pwNewAgain"
                ></b-form-input>
                <div class="input-group-append">
                  <span class="input-group-text">
                    <v-icon :icon="['fas', 'lock']" />
                  </span>
                </div>
              </div>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </validation-provider>
          </div>

          <div class="form-group mt-4 mb-0">
            <button type="submit" class="btn btn-primary btn-block">
              Şifremi Sıfırla
            </button>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import loading from "../../../components/global/loading/index";
import resetPassword from "@/graphql/auth/resetPassword.gql";
import sha256 from "sha256";
import _ from "lodash";
export default {
  name: "Login-page",
  components: {
    loading,
  },
  data() {
    return {
      loading: false,
      form: {
        pwNew: null,
        pwNewAgain: null,
      },
    };
  },
  methods: {
    async formSubmit() {
      try {
        this.loading = true;

        let variable = {
          code: this.$route.params.id,
          password: sha256(this.form.pwNew),
        };
        
        await this.$apollo.mutate(resetPassword, variable);
        this.$bvToast.toast(this.$t("Toaster.password-changed"), {
          title: this.$t("Toaster.success-title"),
          variant: "success",
          autoHideDelay: 1350,
        });

        this.$router.push("/auth/login");
      } catch (e) {
        return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
